import CardValidator from 'card-validator';

import {ReactComponent as Card} from 'assets/images/card.svg';
import {ReactComponent as Wallet} from 'assets/images/wallet.svg';
import {ReactComponent as USSD} from 'assets/images/ussd.svg';
import {ReactComponent as Bank} from 'assets/images/bank.svg';
import {ReactComponent as MoMo} from 'assets/images/card-send.svg';

import VISA from 'assets/images/card/visa.svg';
import MAESTRO from 'assets/images/card/maestro.png';
import DINNER from 'assets/images/card/diners.svg';
import AMEX from 'assets/images/card/amex.png';
import DISCOVER from 'assets/images/card/discover.svg';
import ELO from 'assets/images/card/elo.svg';
import HIPERCARD from 'assets/images/card/hipercard.svg';
import JCB from 'assets/images/card/jcb.png';
import MASTERCARD from 'assets/images/card/mastercard.png';
import MIR from 'assets/images/card/mir.png';
import UNION from 'assets/images/card/unionpay.svg';
import VERVE from 'assets/images/card/verve.svg';
import {currencies} from 'components/constants';
import {isObject} from 'utils/helpers';

const allEnvs = ['dev', 'test', 'live'];

const allMethods = [
    {
        name: 'Card',
        Icon: Card,
        slug: 'card',
        envs: allEnvs,
    },
    {name: 'Bank Transfer', Icon: Bank, slug: 'bank-transfer', envs: allEnvs},
    {name: 'USSD', Icon: USSD, slug: 'bank-transfer', envs: allEnvs},
    {
        name: 'MoMo',
        Icon: MoMo,
        slug: 'momo',
        envs: allEnvs,
        group: 'digital-wallets',
    },
    {
        name: 'Opay',
        Icon: MoMo,
        slug: 'opay',
        envs: allEnvs,
        group: 'digital-wallets',
    },
    {name: 'Bank Account', Icon: Wallet, code: 'bankAccount', slug: 'bank-account', envs: []},
];

const additionMethods = [
    {slug: 'opay', name: 'Opay', group: 'digital-wallets'},
    {slug: 'palmpay', name: 'palmpay', group: 'digital-wallets'},
];

export const getPaymentMethods = ({currency, methods = [], cardOnly = false}) => {
    methods = methods.filter(method => method?.slug !== 'bank-account');
    methods = [...methods];

    const env = process.env.REACT_APP_ENVIRONMENT;
    const envMethods = allMethods.filter(({envs}) => envs.includes(env));

    const flatMethods = methods.map(method => method?.slug);

    const newMethods = envMethods.filter(({slug}) => {
        return flatMethods.includes(slug);
    });

    const cardMethod = envMethods.find(v => v?.name === 'Card');

    const filteredMethods =
        cardOnly || currency !== currencies.NGN
            ? [cardMethod]
            : flatMethods.length === 0
            ? envMethods
            : newMethods;

    return filteredMethods;
};

export const getOldPaymentMethods = ({currency, methods = [], cardOnly = false}) => {
    methods = methods.filter(method => method !== 'bankAccount');
    const env = process.env.REACT_APP_ENVIRONMENT;
    const envMethods = allMethods.filter(({envs}) => envs.includes(env));
    const flatMethods = methods.reduce((acc, arr) => {
        const isAnObject = isObject(arr);
        if (typeof arr === 'string') {
            return [...acc, arr];
        } else if (isAnObject) {
            const innerObjs = Object.keys(arr) || [];
            const innerObj = innerObjs?.length ? innerObjs?.[0] : {};
            const innerObjValue = arr?.[innerObj];

            if (isObject(innerObjValue)) {
                const newArrayData = Object.keys(innerObjValue) || [];
                return [...acc, ...newArrayData];
            } else if (innerObjValue?.length) {
                return [...acc, ...innerObjValue];
            }
            return acc;
        } else return acc;
    }, []);

    const newMethods = envMethods.filter(({code}) =>
        typeof code === 'string'
            ? flatMethods.includes(code)
            : code.some(element => flatMethods.includes(element))
    );
    const cardMethod = envMethods?.find(v => v?.name === 'Card');
    const filteredMethod =
        cardOnly || currency !== currencies.NGN
            ? [cardMethod]
            : flatMethods.length === 0
            ? envMethods
            : newMethods;

    return filteredMethod;
};

export const validCardInfo = {
    niceType: '',
    type: '',
    gaps: [4, 10],
    lengths: [20],
    code: {name: 'CVV', size: 3},
};

export const nextData = {
    cardNumber: true,
    expiryDate: true,
    cvv: true,
};

export const CARD_IMAGES = {
    visa: VISA,
    mastercard: MASTERCARD,
    'american-express': AMEX,
    'diners-club': DINNER,
    discover: DISCOVER,
    jcb: JCB,
    unionpay: UNION,
    maestro: MAESTRO,
    mir: MIR,
    elo: ELO,
    hipercard: HIPERCARD,
    hiper: '',
    verve: VERVE,
};

const clearNumber = (value = '') => value.replace(/\D+/g, '');

const verveCard = {
    niceType: 'Verve',
    type: 'verve',
    code: {name: 'CVV', size: 3},
    lengths: [19],
};

CardValidator.creditCardType.addCard({
    ...verveCard,
    patterns: [5060, 5061, 5078, 5079, 6500],
    gaps: [4, 8, 12],
});

CardValidator.creditCardType.changeOrder('verve', 0);

export const formatCardNumber = value => {
    if (!value) return value;

    let cardInfo = CardValidator.number(value) || {};
    const clearValue = clearNumber(value);

    const isVerve = /^((506(0|1))|(507(8|9))|(6500))[0-9]{4,19}$/.test(clearValue);

    cardInfo = isVerve ? {...cardInfo, card: {...cardInfo?.card, ...verveCard}} : cardInfo;
    let nextValue;

    switch (cardInfo?.card?.type) {
        case 'american-express':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(
                10,
                15
            )}`;
            break;

        case 'diners-club':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(
                10,
                14
            )}`;
            break;

        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(
                8,
                12
            )} ${clearValue.slice(12, 19)}`;
            break;
    }

    const len = clearValue?.length;

    const verveLength = len === 19 || len === 18 || len === 17 || len === 16;
    const next = isVerve ? verveLength : cardInfo?.isValid && cardInfo?.isPotentiallyValid;

    if (
        (isVerve && !verveLength) ||
        (!isVerve && !cardInfo?.isValid && !cardInfo?.isPotentiallyValid)
    )
        cardInfo.error = 'Invalid Card Number';
    else cardInfo.error = '';

    return {...cardInfo, isVerve, next, newValue: nextValue.trim()};
};

export const formatExpirationDate = value => {
    let clearValue = clearNumber(value);
    let dateObj = {};

    if (clearValue.length >= 3) {
        clearValue = `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
        const validDate = CardValidator.expirationDate(clearValue);
        const {isPotentiallyValid, isValid} = validDate || {};

        dateObj.next = isValid && isPotentiallyValid && clearValue.length === 5;

        if (!isPotentiallyValid && !isValid) {
            dateObj.error = '';
            // dateObj.error = 'Invalid Date Supplied';
        } else dateObj.error = '';
    }

    return {...dateObj, newValue: clearValue};
};

export const allUSDBanks = [
    {
        label: 'Access Bank',
        code: '901',
        ussdCode: '901*2',
    },
    {
        label: 'Eco Bank',
        code: '326',
        ussdCode: '326',
    },
    {
        label: 'Fidelity Bank',
        code: '770',
        ussdCode: '770',
    },
    {
        label: 'First Bank',
        code: '894',
        ussdCode: '894',
    },
    {
        label: 'FCMB',
        code: '329',
        ussdCode: '329',
    },
    {
        label: 'GT Bank',
        code: '737',
        ussdCode: '737*2',
    },
    {
        label: 'Heritage Bank',
        code: '745',
        ussdCode: '745*2',
    },
    {
        label: 'Keystone Bank',
        code: '7111',
        ussdCode: '7111',
    },
    {
        label: 'Polaris Bank',
        code: '833',
        ussdCode: '833',
    },
    {
        label: 'Providus Bank',
        code: '826',
        ussdCode: '826',
    },
    {
        label: 'Stanbic IBTC',
        code: '909',
        ussdCode: '909*22',
    },
    {
        label: 'Sterling Bank',
        code: '822',
        ussdCode: '822*5',
    },
    {
        label: 'UBA',
        code: '919',
        ussdCode: '919*4',
    },
    {
        label: 'Union Bank',
        code: '826',
        ussdCode: '826*2',
    },
    {
        label: 'Unity Bank',
        code: '7799',
        ussdCode: '7799*2',
    },
    {
        label: 'Wema Bank',
        code: '945',
        ussdCode: '945',
    },
    {
        label: 'Zenith Bank',
        code: '966',
        ussdCode: '966',
    },
];

export const payWithBankAccountDefaultValues = {
    phoneNumber: '',
    bankCode: '',
    bankName: '',
    accountNumber: '',
    narration: '',
    sessionId: '',
    transReference: '',
    amount: 0,
    otp: '',
    checkoutReference: '',
    customerEmail: '',
};

export const stagingBankAccount = {
    phoneNumber: '+2349012345678',
    bankCode: '000',
    bankName: 'BLUSALT',
    accountNumber: '0123456789',
    accountName: 'Bender Rodriguez',
};

'american-express' |
    'diners-club' |
    'discover' |
    'elo' |
    'hiper' |
    'hipercard' |
    'jcb' |
    'maestro' |
    'mastercard' |
    'mir' |
    'unionpay' |
    'visa';

export const creditCardTypeObj = {
    'American Express': 'american-express',
    'Diners Club': 'diners-club',
    Discover: 'discover',
    Elo: 'elo',
    Hiper: 'hiper',
    Hipercard: 'hipercard',
    JCB: 'jcb',
    Maestro: 'maestro',
    Mastercard: 'masterCard',
    Mir: 'mir',
    UnionPay: 'unionpay',
    Visa: 'visa',
    Verve: 'verve',
};
