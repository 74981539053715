import React from 'react';
import InvoiceDescription from '../InvoiceDescription/InvoiceDescription';
import InvoiceHeader from '../InvoiceHeader/InvoiceHeader';
import InvoicePrice from '../InvoicePrice/InvoicePrice';
import ItemsTable from '../ItemsTable/ItemsTable';
import Footer from 'components/Common/Footer';
import {useApps} from 'components/App/AppContext';

const InvoiceContent = ({invoice, refetch}) => {
    const {other_info, currency, settings, notes} = invoice || {};
    const {metadata} = useApps();
    const logo = invoice?.logo ?? metadata?.company_logo;

    return (
        <>
            <InvoiceHeader invoice={invoice} refetch={refetch} />
            <div className="form-content px-5">
                <InvoiceDescription invoice={invoice} />
                <ItemsTable items={other_info?.calculated_items} currency={currency} />
                <InvoicePrice invoice={invoice} currency={currency} />

                <Footer logo={logo} poweredBy="Invoice" cls="px-0 border-0" />
            </div>
            <div className="invoice__footnote">
                <span>{settings?.default_memo}</span>
                <p>{notes ?? settings?.default_footer}</p>
            </div>
        </>
    );
};

export default InvoiceContent;
