import {useState, createContext, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {getOpayVerificationLink} from 'api/routes/wallet';
import {useQuery} from 'react-query';

export const STEPS = {
    INITIAL: 'INITIAL',
    PENDING: 'PENDING',
    CONFIRM: 'CONFIRM',
    FAILED: 'FAILED',
};

export const PayWIthOpayContext = createContext({});
export const usePayWithOpay = () => useContext(PayWIthOpayContext);

export const PayWithOpayProvider = ({checkoutData = {}, children}) => {
    const [currentState, setCurrentState] = useState(STEPS.INITIAL);
    const [errorMsg, setErrorMsg] = useState('');
    const [externalLink, setExternalLink] = useState();

    const {reference} = useParams();

    const {metadata, API, apiVersion} = checkoutData;
    const Authorization = metadata?.merchant?.publicKey;
    const config = {headers: {Authorization}};

    const updateCurrentState = newState => {
        setErrorMsg('');
        setCurrentState(newState);
    };

    const setError = () => {
        setErrorMsg('Unable to initialize payment');
        setCurrentState(STEPS.FAILED);
    };

    const getVerificationLinkQuery = useQuery(
        ['get-verification-link', reference],
        async () => {
            const response = await API.put(
                getOpayVerificationLink(reference, apiVersion),
                {call_back_url: `${window.location.origin}/error`},
                config
            );
            return response?.data;
        },
        {
            onSuccess: data => {
                if (data?.data) {
                    setExternalLink(data?.data);
                    updateCurrentState(STEPS.PENDING);
                } else setError();
            },
            onError: setError,
            enabled: !!reference,
            retry: false,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    );

    return (
        <PayWIthOpayContext.Provider
            value={{
                currentState,
                errorMsg,
                externalLink,
                setExternalLink,
                updateCurrentState,
                getVerificationLinkQuery,
            }}
        >
            {children}
        </PayWIthOpayContext.Provider>
    );
};
