import CustomInputBox from 'utils/CustomInput/CustomInputBox';
import CARD_NULL from 'assets/images/card/credit-card.svg';
import {ReactComponent as BackSide} from 'assets/images/card/backside.svg';
import * as utils from 'components/Checkouts/Checkout/extra';
import CustomSpinner from 'utils/Spinner/Spinner';
import {STEPS, usePayWithCard} from './PayWithCardContext';
import {CustomCheckBox} from 'utils/CustomInput/CustomCheckBox';

const PayWithCardForm = () => {
    const {
        cvvLength,
        card,
        dateRef,
        cvvRef,
        pinRef,
        cardInfo,
        cardDetails,
        loading,
        formValid,
        formErrors,
        setValue,
        setFormErrors,
        handleChange,
        handleCVV,
        handleCardPayment,
        updateCurrentState,
    } = usePayWithCard();

    const cardExist = !!cardDetails?.length;

    const card_image = cardInfo?.type ? utils.CARD_IMAGES[cardInfo?.type] : CARD_NULL;
    const card_length = cardInfo?.lengths?.length
        ? cardInfo?.lengths[0] + cardInfo.gaps.length
        : 20;

    const handleSubmit = e => {
        e.preventDefault();
        const {cardNumber, expiryDate, cvv} = formValid || {};
        if (cardNumber || expiryDate || cvv) setFormErrors(formValid);
        else handleCardPayment();
    };

    return (
        <>
            <div className="text-center pt-3">
                <span className="g-bold text--gray">Fill in your card details to make payment</span>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="card__form">
                    <div className="row align-items-top">
                        <div className="col-12">
                            <CustomInputBox
                                name="cardNumber"
                                label="Card Number"
                                value={card?.cardNumber}
                                placeholder="Card Number"
                                maxLength={card_length}
                                error={formErrors?.cardNumber ? 'Invalid Card Number' : false}
                                onChange={handleChange}
                                iconText={<img src={card_image} alt="" />}
                            />
                        </div>

                        <div className="col-sm-6">
                            <CustomInputBox
                                inputRef={dateRef}
                                name="expiryDate"
                                label="Expiry Date"
                                value={card?.expiryDate}
                                placeholder="MM/YY"
                                pattern="\d\d/\d\d"
                                error={formErrors?.expiryDate ? 'Invalid Date' : false}
                                maxLength={5}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-sm-6">
                            <CustomInputBox
                                name="cvv"
                                type="password"
                                inputRef={cvvRef}
                                label={cardInfo?.code?.name || 'CVV'}
                                value={card?.cvv}
                                placeholder={cardInfo?.code?.name || 'CVV'}
                                maxLength={cvvLength}
                                error={
                                    formErrors?.cvv
                                        ? `Invalid ${cardInfo?.code?.name ?? 'CVV'}`
                                        : false
                                }
                                onChange={handleCVV}
                                pattern="\d{3,4}"
                                iconText={<BackSide className="gray" height={20} width={25} />}
                            />
                        </div>

                        <div className="col-sm-6">
                            <CustomCheckBox
                                cls="mb-3"
                                label="Remember this card"
                                name="saveCard"
                                checked={card.saveCard}
                                onClick={() => setValue('saveCard', !card.saveCard)}
                            />
                        </div>

                        {cardInfo?.showPin && (
                            <div className="col-sm-6">
                                <CustomInputBox
                                    name="pin"
                                    type="password"
                                    inputRef={pinRef}
                                    value={card?.pin}
                                    placeholder="Enter your pin"
                                    error={formErrors?.pin}
                                    onChange={handleChange}
                                    maxLength={4}
                                    minLength={4}
                                />
                            </div>
                        )}
                    </div>
                </div>

                {cardExist && (
                    <div
                        className="col-12 text--primary fw-bold py-2 text-decoration-underline pointer"
                        onClick={() => updateCurrentState(STEPS.SELECT_OPTIONS)}
                    >
                        Use a Saved Card
                    </div>
                )}
                <div className="col-12 pt-3 px-0">
                    <button
                        className="btn btn--lg btn--primary g-bold"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? <CustomSpinner /> : 'Make Payments'}
                    </button>
                </div>
            </form>
        </>
    );
};

export default PayWithCardForm;
