import {useEffect, useState} from 'react';
import queryString from 'query-string';
import {paymentAPI, walletAPI} from 'api';
import {initializeCheckout} from 'api/routes/wallet';
import BigSpinner from 'utils/Spinner/BigSpinner';
import InitializeError from '../Checkout/common/InitializeError';
import {CLOSE, PAYMENT, responseStatus} from 'components/constants';
import {getError} from 'utils/helpers';
import {CheckoutLayout} from '../CheckoutSC';

const {LOADING, FAILED, IDLE} = responseStatus;

const CheckoutInitialize = ({location, history}) => {
    const [status, setStatus] = useState(IDLE);
    const [errorMsg, setErrorMsg] = useState();

    const {pathname, search} = location || {};
    const API = pathname.includes(PAYMENT) ? paymentAPI : walletAPI;
    const apiVersion = pathname.includes(PAYMENT) ? '/api/v1' : '';

    const checkoutParams = queryString.parse(search);
    let {a, k, id, cur, tr, metadata, returnUrl, websiteUrl} = checkoutParams;
    metadata = metadata ? JSON.parse(metadata) : {};

    useEffect(() => {
        getParams();
        // eslint-disable-next-line
    }, []);

    const sendEvent = data => window.parent.postMessage(data, '*');

    const handleClose = ({eventData = {}}) => {
        const {redirectCancelledUrl} = metadata;
        sendEvent({name: CLOSE, ...eventData});
        redirectCancelledUrl && (window.location.href = redirectCancelledUrl);
    };

    const getParams = () => {
        if (!k && !id && !a && !cur) setError('Some Parameters are missing.');
        else if (!k) setError('API key is missing.');
        else if (!id) setError('Wallet ID is missing.');
        else if (!a) setError('Amount is missing.');
        else if (!cur) setError('Currency is missing.');
        else
            initialize({
                Authorization: k,
                walletId: id,
                amount: a,
                currency: cur,
                transaction_reference: tr,
                metadata,
                returnUrl,
            });
    };

    const initialize = async ({
        Authorization,
        walletId,
        amount,
        currency,
        transaction_reference,
        metadata,
        returnUrl,
    }) => {
        try {
            setStatus(LOADING);

            const response = await API.post(
                `${apiVersion}${initializeCheckout}`,
                {
                    amount: Number(amount),
                    currency,
                    walletId,
                    returnUrl,
                    metadata,
                    transaction_reference,
                },
                {headers: {Authorization}}
            );

            const {status, data} = response || {};

            if (status === 200 && (data.status || data.success)) {
                const url = apiVersion ? '/payments' : '';
                history.replace(
                    `${url}/${data?.data?.reference}?frame=true&websiteUrl=${websiteUrl}`
                );
            } else setError(data?.message);
        } catch (error) {
            setError(getError(error.response?.data || error.response));
        }
    };

    const setError = message => {
        setStatus(FAILED);
        setErrorMsg(message);
    };

    const renderByStatus = () => {
        switch (status) {
            case LOADING:
                return (
                    <div className="init__loader">
                        <BigSpinner />
                    </div>
                );

            case FAILED:
                return (
                    <InitializeError
                        isFrame
                        errorMsg={errorMsg}
                        retry={getParams}
                        sendEvent={handleClose}
                    />
                );
            default:
                return '';
        }
    };
    return (
        <CheckoutLayout className="checkout">
            <div className="checkout__container">{renderByStatus()}</div>
        </CheckoutLayout>
    );
};

export default CheckoutInitialize;
