import React, {useState, useEffect, useContext} from 'react';
import {useParams} from 'react-router-dom';
import API from 'api';
import {getInvoiceInfo} from 'api/routes/invoice';
import {AppMeta} from 'components/App/AppMeta';
import {responseStatus} from 'components/constants';
import BigSpinner from 'utils/Spinner/BigSpinner';
import FailedState from 'utils/ResponseState/FailedState';
import {getError, getVersion} from 'utils/helpers';
import Layout, {InvoiceContext} from '../Layout/Layout';
import InvoiceContent from './InvoiceContent/InvoiceContent';
import {useApps} from 'components/App/AppContext';

const {LOADING, SUCCESS, FAILED} = responseStatus;

const Invoice = ({location}) => {
    const [status, setStatus] = useState(LOADING);
    const [errorMsg, setErrorMsg] = useState();
    const [invoice, setInvoice] = useState({});

    const {metadata} = useApps();
    const showTransparent = status === LOADING || errorMsg;
    const primaryColor = invoice?.primaryColor || metadata?.brand_primary_color;
    const secColor = invoice?.secondaryColor || metadata?.brand_secondary_color;

    const colors = {primaryColor, secColor};

    const {API: checkoutAPI, apiVersion} = getVersion(location);

    const old = useContext(InvoiceContext);

    const {reference} = useParams();

    useEffect(() => {
        getInvoice();
        // eslint-disable-next-line
    }, []);

    const setError = error => {
        setStatus(FAILED);
        setErrorMsg(getError(error));
    };

    const getInvoice = async () => {
        try {
            setStatus(LOADING);

            const response = await API.get(getInvoiceInfo(reference));

            const {status, data} = response || {};

            if (status === 200 && data.status) {
                setStatus(SUCCESS);
                setInvoice(data?.data);
                old?.setMeta &&
                    old?.setMeta(data?.data?.metadata?.checkoutData?.metadata?.metadata || {});
            } else setError(data?.message);
        } catch (error) {
            setError(error.response);
        }
    };

    const renderByStatus = () => {
        switch (status) {
            case LOADING:
                return (
                    <div className="mt-5 pt-5 flex__center ">
                        <BigSpinner />;
                    </div>
                );
            case FAILED:
                return (
                    <div className="mt-5 pt-5 flex__center ">
                        <FailedState errorMsg={errorMsg} refetch={getInvoice} />
                    </div>
                );
            case SUCCESS:
                return (
                    <InvoiceContent
                        invoice={{...invoice, API: checkoutAPI, apiVersion, colors}}
                        refetch={getInvoice}
                    />
                );

            default:
                break;
        }
    };

    return (
        <>
            <AppMeta name={invoice?.other_info?.merchant?.name} title="Invoice System" />
            <Layout
                colors={{
                    primaryColor: showTransparent ? 'transparent' : primaryColor,
                    secColor: showTransparent ? 'transparent' : secColor,
                }}
                logoURL={showTransparent ? '' : invoice?.logo || metadata?.company_logo}
            >
                <div className="invoice__content">{renderByStatus()}</div>
            </Layout>
        </>
    );
};

export default Invoice;
