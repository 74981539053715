import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getCheckoutData} from 'api/routes/wallet';
import BigSpinner from 'utils/Spinner/BigSpinner';
import InitializeError from './common/InitializeError';
import CheckoutContent from './CheckoutContent/CheckoutContent';
import {CLOSE, responseStatus} from 'components/constants';
import {CheckoutLayout} from '../CheckoutSC';
import {getError, getVersion, pageInIframe} from 'utils/helpers';
import {getPaymentMethods} from './extra';
import {useApps} from 'components/App/AppContext';
import {AppMeta} from 'components/App/AppMeta';
import './Checkout.scss';

const {LOADING, FAILED, SUCCESS, IDLE} = responseStatus;

const Checkout = ({location}) => {
    const {colors} = useApps();
    const [status, setStatus] = useState(IDLE);
    const [errorMsg, setErrorMsg] = useState();
    const [methods, setMethods] = useState([]);
    const [checkoutData, setCheckoutData] = useState({});
    const [isIframe, setIframe] = useState(false);

    const {reference} = useParams();
    const isAggregator = checkoutData?.metadata?.isAggregator;

    const metaD = isAggregator
        ? {colors}
        : checkoutData?.metadata?.merchant?.primaryColor
        ? {
              colors: {
                  primaryColor: checkoutData?.metadata?.merchant?.primaryColor,
                  secColor: checkoutData?.metadata?.merchant?.secondaryColor,
              },
          }
        : checkoutData?.metadata?.paylink?.metadata?.primaryColor
        ? checkoutData?.metadata?.paylink?.metadata
        : checkoutData?.metadata?.primaryColor || checkoutData?.metadata?.colors
        ? checkoutData?.metadata
        : {colors};

    const {API, apiVersion} = getVersion(location);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setIframe(pageInIframe());
        }
        handleCheckoutData();
        // eslint-disable-next-line
    }, []);

    const sendEvent = data => window.parent.postMessage(data, '*');

    const updateCheckoutData = (data = {}) => setCheckoutData(prev => ({...prev, ...data}));

    const handleCheckoutData = async () => {
        try {
            const response = await API.get(getCheckoutData(reference, apiVersion));

            const {status, data} = response || {};

            if (status === 200 && (data.status || data.success)) {
                if (data?.data) {
                    setStatus(SUCCESS);
                    setCheckoutData(data?.data);
                    setMethods(
                        getPaymentMethods({
                            currency: data?.data?.currency,
                            methods: data?.data?.paymentMethods || [],
                            cardOnly: data?.data?.metadata?.cardOnly,
                        })
                    );
                } else setError('Transaction not valid!');
            } else setError(data?.message);
        } catch (error) {
            setError(getError(error));
        }
    };

    const setError = message => {
        setStatus(FAILED);
        setErrorMsg(message);
    };

    const handleClose = ({eventData = {}}) => {
        const {redirectCancelledUrl} = checkoutData?.metadata || {};
        sendEvent({name: CLOSE, ...eventData});
        redirectCancelledUrl && (window.location.href = redirectCancelledUrl);
    };

    const renderByStatus = () => {
        switch (status) {
            case LOADING:
                return (
                    <div className={`${isIframe ? 'init__loaders' : 'loader'}`}>
                        <BigSpinner />
                    </div>
                );

            case FAILED:
                return (
                    <InitializeError
                        errorMsg={errorMsg}
                        btnName="Close"
                        retry={handleClose}
                        sendEvent={handleClose}
                    />
                );

            case SUCCESS:
                return (
                    <CheckoutContent
                        checkoutData={{
                            ...checkoutData,
                            metaD,
                            API,
                            apiVersion,
                            frame: isIframe,
                            methods,
                            setMethods,
                            handleClose,
                            sendEvent: sendEvent,
                            updateCheckoutData,
                        }}
                    />
                );
            default:
                return '';
        }
    };
    return (
        <>
            <AppMeta name={checkoutData?.metadata?.merchant?.name} />
            <CheckoutLayout colors={metaD?.colors} className="checkout">
                <div className="checkout__container">
                    <div className="checkout__main">{renderByStatus()}</div>
                </div>
            </CheckoutLayout>
        </>
    );
};

export default Checkout;
