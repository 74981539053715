import {useEffect} from 'react';
import {ReactComponent as Danger} from 'assets/images/danger.svg';
import PayWithCardForm from './PayWithCardForm';
import WaitingForPayment from 'components/Checkouts/Checkout/common/WaitingForPayment';
import StatusMessage from 'components/Checkouts/Checkout/common/StatusMessage';
import OTPVerification from 'components/Checkouts/Checkout/common/OTPVerification';
import PaymentConfirmationFrame from 'components/Checkouts/Checkout/common/PaymentConfirmationFrame';
import {STEPS, usePayWithCard} from './PayWithCardContext';
import {ExistingCardsList} from './ExistingCardsList';
import BigSpinner from 'utils/Spinner/BigSpinner';
import Alert from 'utils/Alert/Alert';

const PayWithCard = () => {
    const {errorMsg, reason, updateError, receiveMessage, getExistingCard} = usePayWithCard();
    const {VerifyVerveOtp, currentState, reset, loading, verveMessage, url} = usePayWithCard();

    useEffect(() => {
        getExistingCard();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        window.addEventListener('message', receiveMessage, false);
        return () => {
            window.removeEventListener('message', receiveMessage, false);
        };
        // eslint-disable-next-line
    }, [currentState]);

    const render = () => {
        switch (currentState) {
            case STEPS.INITIAL:
            default:
                return (
                    <div className="flex__center py-5">
                        <BigSpinner />
                    </div>
                );

            case STEPS.NEW_CARD:
                return <PayWithCardForm />;

            case STEPS.PENDING:
                return <PaymentConfirmationFrame url={url} />;

            case STEPS.CONFIRM:
            case STEPS.WAITING_FOR_PAYMENT:
                return <WaitingForPayment />;

            case STEPS.SELECT_OPTIONS:
                return <ExistingCardsList />;

            case STEPS.FAILED:
                return (
                    <div className="mx-auto pt-5">
                        <div className="flex__center">
                            <Danger width={30} height={30} />
                        </div>
                        <StatusMessage
                            title="Transaction Failed!"
                            message={reason}
                            btnName="Retry"
                            handleClick={reset}
                        />
                    </div>
                );
            case STEPS.OTP_VERIFICATION:
                return (
                    <OTPVerification
                        loading={loading}
                        handleSubmit={VerifyVerveOtp}
                        message={verveMessage}
                    />
                );
        }
    };

    return (
        <>
            {errorMsg && <Alert {...errorMsg} onClose={() => updateError()} />}
            {render()}
        </>
    );
};

export default PayWithCard;
