import {STEPS, usePayWithOpay} from './PayWithOpayProvider';
import PaymentConfirmationFrame from 'components/Checkouts/Checkout/common/PaymentConfirmationFrame';
import BigSpinner from 'utils/Spinner/BigSpinner';
import {ReactComponent as Danger} from 'assets/images/danger.svg';
import StatusMessage from 'components/Checkouts/Checkout/common/StatusMessage';

export const PayWithOpay = () => {
    const context = usePayWithOpay();
    const {currentState, errorMsg, externalLink} = context;

    const render = () => {
        switch (currentState) {
            case STEPS.INITIAL:
            default:
                return (
                    <div className="flex__center py-5">
                        <BigSpinner />
                    </div>
                );

            case STEPS.PENDING:
                return <PaymentConfirmationFrame url={externalLink} />;

            case STEPS.FAILED:
                return (
                    <div className="mx-auto pt-5">
                        <div className="flex__center">
                            <Danger width={30} height={30} />
                        </div>
                        <StatusMessage title="Transaction Failed!" message={errorMsg} />
                    </div>
                );
        }
    };
    return <>{render()}</>;
};
