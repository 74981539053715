import * as React from 'react';
import Iframe from 'react-iframe';

export const PaymentConfirmationFrame = ({url}) => {
    return (
        <div className="confirmation__frame__wrapper">
            <Iframe url={url} id="payment" className="confirmation__frame" ena />
        </div>
    );
};

export default PaymentConfirmationFrame;
